<template>
	<div ref="gProgress" v-loading.fullscreen.lock="isGlobalBlockingProgressShowing"
		 class="qwoach-root"
		 :class="{'custom-branding-styling': isCustomStyleEnabled}"
		 :element-loading-text="progressText" :style="dynamicCssVars">
		<span v-if="!isAuthReady || isUpgradeChecking || isUpgrading">
			<div id="loading">
				<div class="spinner">
					<div class="bounce1"></div>
					<div class="bounce2"></div>
					<div class="bounce3"></div>
					<span v-if="isUpgrading" class="text text-center">
						<div>Upgrading Qwoach to the latest version. Please wait...</div>
						<div v-if="totalMigrationSteps > 1" class="mt-2" style="width: 50%; margin: 0 auto;">
							<el-progress :percentage="upgradePercentCompleted"/>
							<!--							Completed {{this.currentMigrationStep}} out of {{this.totalMigrationSteps}}-->
						</div>
					</span>
				</div>

			</div>
		</span>

		<router-view v-else></router-view>
		<dialogs-wrapper transition-name="modal"></dialogs-wrapper>
		<vue-progress-bar></vue-progress-bar>
	</div>
</template>

<script>

import BaseComponent from "@/core/components/BaseComponent.vue";
import DatabaseMigrationManager from "@/utils/dataMigration/database/DatabaseMigrationManager";
import {hexToHSL, hslObjToString, logError} from "@/utils/helpers";

export default {
	extends: BaseComponent,
	name: 'App',

	data() {
		return {
			isGlobalBlockingProgressShowing: false,
			progressText: "Loading...",
			isAuthReady: false,
			isUpgradeChecking: false,
			isUpgrading: false,
			databaseMigrationManager: null,
			primaryColor: "pink",
			currentMigrationStep: 0,
			totalMigrationSteps: 0,
		}
	},

	created() {
		console.log("App created")
		this.setupMigration()
		this.setupProgressBar()
		this.setupAuthState()
	},

	methods: {
		setupAuthState() {

			console.log("App.setupAuthState start")
			let unsubscribe = this.$authManager.startListenForLoginStateChange(async (userProfile, error) => {
				if(error){
					await logError(error)
					return
				}
				console.log("App.setupAuthState finish")
				if (!this.isAuthReady) {

					await this.setupEmbeddedState()
					if (userProfile && window.fcWidget) {
						window.fcWidget.setExternalId(userProfile.id);
						window.fcWidget.user.setEmail(userProfile.email);
					}
					this.$Progress.finish()
				}

				if (userProfile) {
					await this.$authManager.startListenForClaimsChange()
					await this.upgradeDataBaseSchema()
				}
				this.isAuthReady = true

				// if (userProfile) { //only unsubscribe after user logs in to make sure upgradeDataBaseSchema is called
				//later comment: that was a bad idea - there are free tools that user can play around with before signing up.
				// If we don't unsubscribe in the very beginning, user will lose all their data because login will trigger this callback that triggers isUpgradeChecking/isUpgrading which will cause destruction of all views
				// Need to thing of a more sophisticated way of checking for upgrade after login, but at this point it's a minor case so just skiipping it and unsubscribing right away.
				// await this.getScopomaxAssessment()
				unsubscribe()

				// if(userProfile && userProfile.id === "rv9BXOFtezUiCf4iRtMv4svh5WN2"){
				// try{
				// 	await this.restoreTestUser()
				// } catch (error){
				// 	logError(error)
				// }
				// }
				// }
			}, error => {
				throw error
			})
		},

		async getScopomaxAssessment(){
			let assessment = await this.dataManager.getAssessmentPublic("Corp9wBL7BSHMgQf4mMnOyS31mD3", "bZZgExRrkiiVVJR3Nzvu")
			console.log("getScopomaxAssessment: ", JSON.stringify(assessment))

		},

		async setupEmbeddedState() {
			let searchParams = new URL(location.href).searchParams
			let isQwoachEmbedded = searchParams.get('app-embedded') === "true" || false
			let embeddedUid = searchParams.get('embedded-uid')|| null// || this.$route.params.coachId || null // this.$route.params.coachId gives false positive when in path like /coach/:coachId
			console.log("setupEmbeddedState: ", isQwoachEmbedded, ", embeddedUid = ", embeddedUid, ", this.$route.params.coachId = ", this.$route.params.coachId, ", this.$route.params = ", this.$route.params, ", location.href = ", location.href)
			if (embeddedUid) {
				let coachProfile = await this.dataManager.apiGetUserProfilePublic({uid: embeddedUid})
				// if(!this.coachProfile){
				this.$store.commit('setEmbeddedCoachProfile', coachProfile)
				// }
			}
			this.$store.commit('setIsQwoachEmbedded', isQwoachEmbedded)
			this.$store.commit('setEmbeddedUid', embeddedUid)
			console.log("this.isQwoachEmbedded = ", isQwoachEmbedded, ", embeddedUid = g", embeddedUid, ", this.$route =", this.$route, ", location.href = ", location.href)
		},


		setupProgressBar() {
			this.$Progress.start()
			this.$router.beforeEach((to, from, next) => {
				console.log("beforeEach in App.vue")
				if (to.meta.progress !== undefined) {
					let meta = to.meta.progress
					this.$Progress.parseMeta(meta)
				}
				this.$Progress.start()
				next()
			})
			this.$router.afterEach((to, from) => {
				this.$Progress.finish()
			})
		},

		setupMigration() {
			this.databaseMigrationManager = new DatabaseMigrationManager(this.$dataManager, this.$authManager, this.$analyticManager)
		},

		async upgradeDataBaseSchema() {
			try {
				this.isUpgradeChecking = true
				let needsUpgrade = await this.databaseMigrationManager.needsUpgrade()
				console.log("databaseMigrationManager: needsUpgrade = ", needsUpgrade)
				this.isUpgradeChecking = false
				if (needsUpgrade) {
					this.isUpgrading = true
					await this.databaseMigrationManager.startUpgrade((currentMigrationStep, totalMigrationSteps) => {
						this.currentMigrationStep = currentMigrationStep
						this.totalMigrationSteps = totalMigrationSteps
					})
					this.isUpgrading = false
				}
			} catch (error) {
				this.toastError("Can't start application. Please try to refresh or contact support")
				this.logError(error)
				throw error
			}

		}
	},

	computed: {
		isCustomStyleEnabled() {
			let coachProfile = this.embeddedCoachProfile || this.ownerProfile
			let res = coachProfile && coachProfile.isCustomStylingEnabled()
			console.log("isCustomStyleEnabled: ", res, ", this.ownerProfile = ", this.ownerProfile, ", this.embeddedCoachProfile = ", this.embeddedCoachProfile)
			return res
		},

		upgradePercentCompleted() {
			return Math.floor(this.currentMigrationStep / this.totalMigrationSteps * 100)
		},
		dynamicCssVars() {
			let customStyleVars = {}
			let coachProfile = this.embeddedCoachProfile || this.ownerProfile
			if (this.isCustomStyleEnabled) {
				let primaryColor = coachProfile.getPrimaryColor()
				let buttonRadiusPx = coachProfile.brandingSettings.buttonRadiusPx

				let step = 50
				let maxLum = 98
				let base = hexToHSL(primaryColor, 0)
				let {h, s, l} = base
				let hslBackground = {h, s, l: Math.min(l + step, maxLum)}
				let hslBase = {h, s, l: Math.min(hslBackground.l - 1.1 * step, maxLum - step)}
				let hslBaseHover = {h, s, l: base.l + 5}
				let hslBaseColor = hslBase
				let hslBorder = {h, s, l: hslBase.l + step / 5}

				customStyleVars = {
					'--primaryColor2': hslObjToString(base),
					'--primaryColor2_hover-color': hslObjToString(hslBaseColor),
					'--primaryColor2_l40': hslObjToString(hslBorder),
					'--primaryColor2_l60': hslObjToString(hslBackground),
					'--primaryColor2_hover': hslObjToString(hslBaseHover),
					'--button-radius-1': `${buttonRadiusPx}px`,
					'--button-radius-2': `${Math.max(0, buttonRadiusPx - 1)}px`,
				}
			}


			return customStyleVars
		}
	}
}
</script>

<style src="./assets/scss/branding-styles.scss" lang="scss"/>

<style src="./styles/theme.scss" lang="scss"/>
