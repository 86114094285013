import actions from "./actions"

const state = {
	user: null,
	userProfile: null,
	coachId: null,
	coachProfile: null
}

const getters = {
	user: state => state.user,
	isLoggedIn: (state, getters) => getters.user != null,
}

const mutations = {
	SET_USER(state, user) {
		state.user = user;
	},
	SET_USER_PROFILE(state, userProfile) {
		state.userProfile = userProfile;
	},
	SET_COACH_ID(state, coachId) {
		state.coachId = coachId;
	},
	SET_COACH_PROFILE(state, coachProfile) {
		state.coachProfile = coachProfile;
	},
}
export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
