import CommonObject from "@shared/CommonObject";


const actions = {
	getAssessmentPublic(context, request) {
		return CommonObject.dataManager.apiGetAssessmentPublic(request)
			.then(assessment => {
				context.commit('SET_ASSESSMENT', assessment);
			})
	},
	updateAssessmentAssignee(context, request) {
		return CommonObject.dataManager.apiPostAssessmentUpdateAssignee(request)
			.then(assessment => {
				context.commit('SET_ASSESSMENT', assessment);
			})
	},

}

export default actions;
