import BaseObject from "@shared/BaseObject";

export default class ProgramInviteInfo extends BaseObject{

	coachId: string = ""
	programId: string = ""
	coachName: string = null
	programName: string = null
	programDescription: string = null
	isClientExist: boolean = false
	clientEmail: string = null
	rolesArray: string[] = []

	isEnabled: boolean = false
	canAcceptMoreInvites: boolean = false

	constructor() {
		super();
	}

}
