// @flow
import DbBaseObject from '@shared/DbBaseObject.js';


export default class AnswerOption extends DbBaseObject{

	text: string = ""
	value: number = 1

	constructor() {
		super();
	}

}



