import CommonObject from "@shared/CommonObject";

const actions = {
	getNoteListByOwnerId(context, param) {
		return CommonObject.dataManager.getNoteListByOwnerId(param.ownerId)
			.then(noteList => {
				context.commit('SET_NOTE_LIST', noteList);
			})
	},
	actionCreateNote(context, note) {
		console.log("actionCreateNote: note = ", note)
		return CommonObject.dataManager.createNote(note)
			.then(note => {
				context.commit('ADD_NOTE', note);
			})
	},
	actionUpdateNote(context, note) {
		console.log("actionUpdateNote: note = ", note)
		return CommonObject.dataManager.updateNote(note)
			.then(note => {
				context.commit('UPDATE_NOTE', note);
			})
	},
	actionDeleteNote(context, note) {
		return CommonObject.dataManager.deleteNote(note)
			.then(note => {
				context.commit('DELETE_NOTE', note);
			})
	},
}

export default actions;
