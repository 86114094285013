import {Type} from "class-transformer";
import DbBaseObject from '@shared/DbBaseObject';
import QuestionItem from "@shared/Assessment/QuestionItem";
import AssessmentNote from "@shared/Assessment/AssessmentNote";

export const AssigneeStatusEnum = Object.freeze({
	NOT_SET: 'NOT_SET',
	NOTIFIED: 'NOTIFIED',
	COMPLETED: 'COMPLETED',
})


export default class AssigneeItem extends DbBaseObject{

	name: string = null
	email: string = null
	status: AssigneeStatusEnum = AssigneeStatusEnum.NOT_SET

	assessmentUrl: string = null
	assessmentResultsUrl: string = null
	finalReportUrl: string = null

	@Type(() => QuestionItem)
	questions: QuestionItem[] = []
	@Type(() => AssessmentNote)
	finalReportNotes: AssessmentNote[] = []




	constructor(name = null, email = null) {
		super();
		this.name = name;
		this.email = email;
	}

	isNotificationSent(){
		return this.status === AssigneeStatusEnum.NOTIFIED
			|| this.isCompleted()
	}
	isCompleted(){
		return this.status === AssigneeStatusEnum.COMPLETED
	}
	setCompleted(){
		return this.status = AssigneeStatusEnum.COMPLETED
	}

	setupAssessmentUrl(ownerId, assessmentId){
		// it's used both on client and server so need to provide a method to get a domain that works in the both environments
		let domain = "https://qwoach.com"//process.env.VUE_APP_DOMAIN
		this.assessmentUrl = `${domain}/app/assessment/${ownerId}/${assessmentId}/${this.id}/take`
		console.log("this.assessmentUrl = ", this.assessmentUrl)
	}
	setupAssessmentResultsUrl(ownerId, assessmentId){
		// it's used both on client and server so need to provide a method to get a domain that works in the both environments
		let domain = "https://qwoach.com"//process.env.VUE_APP_DOMAIN
		this.assessmentResultsUrl = `${domain}/app/assessment/${ownerId}/${assessmentId}/${this.id}/take/coach-report`
		console.log("this.assessmentResultsUrl = ", this.assessmentResultsUrl)
	}
}



