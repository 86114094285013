<template>
	<el-autocomplete
		ref="autocompleteRef"
		v-bind="$attrs"
	>
		<template #append>
			<slot name="append"></slot>
		</template>

		<template #default="{ item }">
			<slot name="default" :item="item"></slot>
		</template>
	</el-autocomplete>
</template>

<script>

import BaseComponent from "@/core/components/BaseComponent.vue";

export default {
	name: "QwAutocomplete",
	components: {},
	extends: BaseComponent,

	methods: {
		focus() {
			this.refsAutocomplete.inputRef.focus()
		}
	},

	computed: {
		refsAutocomplete() {
			return this.$refs.autocompleteRef.$refs
		}
	},
}
</script>

<style lang="scss">

</style>
