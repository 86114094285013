import CommonObject from "@shared/CommonObject";
import {auth} from "@/core/js/backend/firebaseConfig"
import UserProfile from "@/core/js/model/Profile/UserProfile";
let dataManager
let storageManager
//meh... hack that needs to be resolved properly
// Vue.config.globalProperties.$dataManager is undefined as it now has circular dependency: dataManager relies on store and store relies on dataManager. Not cool
setTimeout(() => {
	dataManager = CommonObject.dataManager
	storageManager = CommonObject.storageManager
	console.log("users: Vue.config.globalProperties.$dataManager = ", CommonObject.dataManager)
}, 500)

const actions = {
	startListenAuthStateChange(context, callback) {
		auth.onAuthStateChanged(user => {
			console.log("onAuthStateChanged: user2 = ", user)
			context.dispatch("onAuthStateChanged", user)
				.then(userProfile => callback(userProfile))
		}, error =>{
			console.error("onAuthStateChanged: error = ", error)
			callback(null, error)
		})

	},

	signup: (context, userData) => {
		console.log("signup: userData = ", userData)
		return auth.createUserWithEmailAndPassword(userData.email, userData.password)
			.then(response => response.user)
			.then(user => {
				userData.uid = user.uid
				return context.dispatch("createUserProfile", userData).then(_ => user)
			}).then(user => {
				return context.dispatch("onAuthStateChanged", user)
			});
	},
	createUserProfile: (context, userData) => {
		let userProfile = new UserProfile(userData.name, userData.email, userData.uid)
		userProfile.role = userData.role;
		console.log("createUserProfile: userProfile = ", userProfile)
		return dataManager.createUserProfile(userProfile)
	},
	restorePassword: (context, email) => {
		return auth.sendPasswordResetEmail(email)
	},
	updateUserProfile: (context, userData) => {
	},
	deleteUser: context => {
	},
	onAuthStateChanged: (context, user) => {
		return Promise.resolve()
			.then(_ => {
				if (user) {
					return context.dispatch("onUserLoggedIn", user)
				} else {
					return context.dispatch("onUserLoggedOut")
				}
		})
	},
	onUserLoggedOut: (context) => {
		console.log("onUserLoggedOut")
		return new Promise(resolve => {
			context.commit('SET_USER', null);
			context.commit('SET_USER_PROFILE', null);
			context.commit('SET_COACH_PROFILE', null);
			context.commit('SET_COACH_ID', null);
			resolve()
		})
	},
	onUserLoggedIn: (context, user) => {
		console.log("onUserLoggedIn: user = ", user)
		let userProfile;
		let coachId;
		dataManager.currentUserId = user.uid
		storageManager.currentUserId = user.uid
		return dataManager.getCurrentUserProfile()
			.then(userProfileLocal => {
				userProfile = userProfileLocal
				coachId = userProfile.getCoachId()
				dataManager.ownerId = coachId
				console.log("onUserLoggedIn: userProfile = ", userProfile)
				return dataManager.getUserProfile(coachId)
			}).then(coachProfile =>{
				context.commit('SET_USER', user);
				context.commit('SET_USER_PROFILE', userProfile);
				context.commit('SET_COACH_PROFILE', coachProfile);
				context.commit('SET_COACH_ID', coachId);
				return userProfile
			})
	},

	login: (context, credentials) => {
		console.log("login: userData = ", credentials)
		return auth.signInWithEmailAndPassword(credentials.email, credentials.password)
			.then(response => response.user)
			.then(user => context.dispatch("onUserLoggedIn", user))

	},
	logout: context => {
		console.log("logout")
		return auth.signOut()
			.then(_ => {
				context.commit("SET_USER", null)
				context.commit('SET_USER_PROFILE', null);
				context.commit('SET_COACH_PROFILE', null);
				context.commit('SET_COACH_ID', null);
			})
	},
}

export default actions;
