import mergeProps from "@/router/utils/mergeProps";
import redirectByName from "@/router/utils/redirectByName.js";

let PlanChooserScreenGeneral = () => import(/* webpackChunkName: "Profile" */  "@/core/modules/coach/Profile/Subscription/PlanChooserScreenGeneral.vue");

let ProfileMain = () => import(/* webpackChunkName: "Profile" */ '@/core/modules/coach/Profile/ProfileMain')
// let ProfileSubscription = () => import(/* webpackChunkName: "Profile" */ '@/core/modules/coach/Profile/Subscription/CurrentSubscriptionView')
// let BrandingSettings = () => import(/* webpackChunkName: "Profile" */ '@/core/modules/coach/Profile/Branding/BrandingSettings')
let EmptyRouterView = () => import(/* webpackChunkName: "Profile" */ '@/core/components/EmptyRouterView.vue')

export default function (baseName = ''){
	let profileMainRoute = baseName + "ProfileMainRoute"
	// console.log('profileMainRoute = ', profileMainRoute)
	return [
		{
			path: 'profile',
			redirect: redirectByName(profileMainRoute, {activeTab: 'first-tab'}),
			component: EmptyRouterView,
			props: mergeProps,
			meta: {
				isWhitelisted: true
			},

			children: [
				{
					path: 'plans',
					name: 'ChooseSubscriptionRouteName',
					component: PlanChooserScreenGeneral,
					props: mergeProps,
				},
				{
					path: ':activeTab',
					name: profileMainRoute,
					component: ProfileMain,
					props: mergeProps,
				},
			]
		},

		{
			path: 'profilerouter-no-path-',
			name: baseName +'ProfileSubscriptionRoute',
			redirect: {name: profileMainRoute, params: {activeTab: 'subscription'}},
		}

	]
}
