// @flow
import DbBaseObject from '@shared/DbBaseObject.js';
import {Type} from "class-transformer";
import AnswerOption from "@shared/Assessment/AnswerOption";

export const QuestionTypeEnum = Object.freeze({
	OPEN_ENDED: 'OPEN_ENDED',
	SINGLE_ANSWER: 'SINGLE_ANSWER',
	MULTIPLE_ANSWERS: 'MULTIPLE_ANSWERS',
})


export default class QuestionItem extends DbBaseObject{

	text: string = ""
	questionType: QuestionTypeEnum = QuestionTypeEnum.OPEN_ENDED

	answer: string = ""
	answerIndex: number = -1

	@Type(() => AnswerOption)
	answerOptions: AnswerOption[] = []

	constructor() {
		super();
	}

}



