import mergeProps from "@/router/utils/mergeProps.js";

let LoginView = () => import(/* webpackChunkName: "Login" */ '@/core/modules/Login/LoginView')
let LoginViewOld = () => import(/* webpackChunkName: "Login" */ '@/core/components/LoginView')
let SignupView = () => import(/* webpackChunkName: "Login" */ '@/core/modules/Login/RegisterScreen.vue')
let SignupSpecialInviteView = () => import(/* webpackChunkName: "Login" */ '@/core/modules/Login/RegisterSpecialInviteView')
let RecoverView = () => import(/* webpackChunkName: "Login" */ '@/core/modules/Login/RestoreAccessView.vue')
let EmptyRouterView = () => import(/* webpackChunkName: "Login" */ '@/core/components/EmptyRouterView.vue')


export default [
	{
		path: '/app',
		redirect: '/app/coaching/processes',
		component: EmptyRouterView,
		props: route => ({
			specialId: route.query.specialId
		}),
		meta:{
			isWhitelisted: true
		},
		children:[
			{
				path: 'login',
				name: 'Login',
				component: LoginView,
			},
			{
				path: 'recover',
				name: 'Recover',
				component: RecoverView,
			},

			{
				path: 'login-coach',
				name: 'LoginCoach',
				component: LoginView,
				props: route => ({
					specialId: route.query.specialId,
					isLoginCoach: true
				}),
			},
			{
				path: 'signup-coach',
				name: 'SignupCoach',
				component: SignupView,
				props: mergeProps,
				meta: {
					isLoginCoach: true
				},
			},
			{
				path: 'join/:teacherId',
				name: 'SignupTeacher',
				component: SignupView,
				props: mergeProps,
				meta: {
					isLoginCoach: true
				},
			},
			{
				path: 'signup-special-invite',
				name: 'SignupCoachSpecialInvite',
				component: SignupSpecialInviteView,
				props: route => ({
					specialId: route.query.specialId,
					isLoginCoach: true
				}),
			},
			{
				path: 'recover-coach',
				name: 'RecoverCoach',
				component: RecoverView,
				props: route => ({
					specialId: route.query.specialId,
					isLoginCoach: true
				}),
			},
		]
	},
	{
		path: '/app/login-coach-old',
		name: 'LoginCoachOld',
		component: LoginViewOld,
		props: route => ({
			specialId: route.query.specialId
		}),
		meta:{
			isWhitelisted: true
		}
	},



]
