import actions from "./actions"

const state = {
	noteList: [],
	noteListPager: null
}

const getters = {
	invoiceList( state ) {
		return state.invoiceList;
	},
}

const mutations = {
	SET_NOTE_LIST(state, noteList) {
		state.noteList = noteList;
	},
	UPDATE_NOTE(state, note) {
		let index = state.noteList.findIndex(item => item.id == note.id)
		// Vue.set(state.noteList, index, note);
		state.noteList[index] = note
	},
	ADD_NOTE(state, note) {
		state.noteList.unshift(note)
	},
	DELETE_NOTE(state, note) {
		let index = state.noteList.findIndex(item => item.id == note.id)
		// Vue.delete(state.noteList, index);
		state.noteList.splice(index, 1);
	},
}
export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
