import DbBaseObject from '@shared/DbBaseObject.js';
import {Type} from "class-transformer";
import {classToClass} from "@qwoach/class-transformer-wrapper";
import QuestionItem from "@shared/Assessment/QuestionItem";
import AssigneeItem from "@shared/Assessment/AssigneeItem";
import NoteItem from "@shared/Profile/NoteItem";
import AssessmentNote from "@shared/Assessment/AssessmentNote";


export default class AssessmentItem extends DbBaseObject{

	@Type(() => QuestionItem)
	questions: QuestionItem[] = []

	@Type(() => AssessmentNote)
	finalReportNotes: AssessmentNote[] = []

	@Type(() => AssigneeItem)
	assigneeList: AssigneeItem[] = []

	assigneeIds: string[] = []

	purchasedFromId: string = ""
	ownerId: string = ""

	title: string = ""
	subTitle: string = ""
	author: string = ""

	completionTipsUrl: string = ""


	constructor() {
		super();
	}

	addAssigneeList(assigneeList: AssigneeItem[]){
		assigneeList.forEach(assignee => {
			assignee.questions = classToClass(this.questions)
			assignee.finalReportNotes = classToClass(this.finalReportNotes)
			this.assigneeIds.push(assignee.id)
			this.assigneeList.push(assignee)
		})
	}
	setupAssessmentLinkUrls(ownerId){
		this.assigneeList.forEach(assignee => {
			assignee.setupAssessmentUrl(ownerId, this.id)
		})
	}
	createPurchasedAssessment(ownerId, assessmentId): AssessmentItem{
		let purchasedAssessment = classToClass(this)
		purchasedAssessment.purchasedFromId = purchasedAssessment.id
		purchasedAssessment.id = assessmentId
		return purchasedAssessment
	}

	findAssigneeById(assigneeId){
		return this.assigneeList.find(assigneeItem => assigneeItem.id == assigneeId)
	}
	setAssignee(assigneeItem){
		let i = 0;
		let res = false
		this.assigneeList.forEach(assignee => {
			if(assignee.id == assigneeItem.id){
				this.assigneeList[i] = assigneeItem
				res = true;
			}
			i++;
		})
		return res
	}


}



