// @flow
import DbBaseObject from '@shared/DbBaseObject.js';


export default class AssessmentNote extends DbBaseObject{

	titleCoach: string = ""
	titleClient: string = ""
	text: string = ""
	minRows: number = 1

	constructor() {
		super();
	}

}



