import actions from "./actions"

const state = {
	assessment: null,
}

const getters = {
}

const mutations = {
	SET_ASSESSMENT(state, assessment) {
		state.assessment = assessment;
	},
}
export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
