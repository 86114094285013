import actions from "./actions"

const state = {
	funnel: null//new OldFunnelItem(),
}

const getters = {
	getFunnel( state ) {
		return state.funnel;
	},
}

const mutations = {
	SET_FUNNEL(state, funnel) {
		state.funnel = funnel;
	},
}
export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
