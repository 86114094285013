import OldFunnelItem from "@/core/modules/coach/Clients/Funnel/model/old/OldFunnelItem";
import CommonObject from "@shared/CommonObject.js";


const actions = {
	funnelFetch(context, param) {
		return CommonObject.dataManager.getFunnelItem()
			.then(funnel => {
				context.commit('SET_FUNNEL', funnel || new OldFunnelItem());
			})
	},
	funnelUpdate(context, funnel) {
		return CommonObject.dataManager.setFunnel(funnel)
			.then(funnel => {
				context.commit('SET_FUNNEL', funnel);
			})
	},

}

export default actions;
