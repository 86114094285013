import mergeProps from "@/router/utils/mergeProps";
import redirectByName from "@/router/utils/redirectByName.js";

let PeopleMain = () => import(/* webpackChunkName: "People" */ '@/core/modules/coach/Clients/PeopleMainView')
let ClientDetails = () => import(/* webpackChunkName: "People" */ '@/core/modules/coach/Clients/ClientDetails.vue')
let ClientList = () => import(/* webpackChunkName: "People" */ '@/core/modules/coach/Clients/ClientList')
let FunnelMain = () => import(/* webpackChunkName: "People" */ '@/core/modules/coach/Clients/Funnel/ClientsFunnelPage')

export default [
	{
		path: 'crm',
		name: 'CrmRoot',
		redirect: redirectByName('CrmTabs', {activeTab: 'clients'}),
		component: PeopleMain,
		props: true,
		children: [
			{
				path: ':activeTab',
				name: 'CrmTabs',
				components: {
					clients: ClientList,
					funnel: FunnelMain
				},
				props: mergeProps,
			},
			// {
			// 	path: ':category',
			// 	name: 'PeopleMain',
			// 	props: true,
			// 	component: PeopleMain,
			// },
			// {
			// 	path: 'clients',
			// 	name: 'ClientsList',
			// 	props: true,
			// 	component: ClientList,
			// },
			// {
			// 	path: 'funnel',
			// 	name: 'FunnelMain',
			// 	props: true,
			// 	component: FunnelMain,
			// },

			{
				path: 'clients/:clientId',
				name: 'ClientDetailsCoach',
				component: ClientDetails
			},

		]
	},

]
