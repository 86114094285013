import { createRouter, createWebHistory } from 'vue-router'



let Layout = () => import(/* webpackChunkName: "helperComponents" */ '@/core/components/Layout/LayoutComponent')
let LayoutClient = () => import(/* webpackChunkName: "helperComponents" */  '@/core/components/Layout/LayoutComponentClient')


const AcceptInvitePage = () => import(/* webpackChunkName: "Coaching" */ '@/core/modules/coach/Invite/AcceptInvitePage')

const ProgramDetailsClient = () => import(/* webpackChunkName: "Coaching" */ '@/core/modules/client/Programs/ProgramDetails')
const ProgramListClient = () => import(/* webpackChunkName: "Coaching" */ '@/core/modules/client/Programs/ProgramList')

const LinkRedirectPage = () => import(/* webpackChunkName: "RedirectChunk" */ '@/core/modules/LinkRedirectPage')


let StripeSetupWizardMain = () => import(/* webpackChunkName: "Stripe" */ '@/core/modules/coach/Billing/view/PaymentIntegrations/StripeWizard/StripeSetupWizardMain')
let CloverSetupWizardMain = () => import(/* webpackChunkName: "Stripe" */ '@/core/modules/coach/Billing/view/PaymentIntegrations/CloverWizard/CloverSetupWizardMain')


let InvoicePay = () => import(/* webpackChunkName: "invoice" */  '@/core/modules/coach/Billing/view/InvoicePay')

import resourcesGlobalRoutes from "@/router/resources/resourcesGlobalRoutes";
import assessmentRoutes from "@/router/assessmentRoutes";
import profileRoutes from "@/router/profileRoutes";
import peopleRoutes from "@/router/crmRoutes";
import toolsRoutes from "@/router/toolsRoutes"

import schedulerRoutes from "@/router/scheduler/schedulerRoutes";
import loginRoutes from "@/router/loginRoutes";
import publicSchedulerRoutes from "@/router/scheduler/publicSchedulerRoutes";
import RouterManager from "@/router/RouterManager";
import coachingProgramRoutes from "@/router/coaching/coach/coachingProgramRoutes";

import recoveryCoaching from "./recoveryCoaching/index.js";
import packageGlobalRoutes from "./recoveryCoaching/packageGlobalRoutes";
import {fromBase64} from "@/utils/helpers";
import workflowGlobalRoutes from "@/router/recoveryCoaching/workflowGlobalRoutes";
let FirebaseActionPage = () => import(/* webpackChunkName: "helperComponents" */  "@/core/modules/Login/FirebaseActionPage.vue");




let routerOptions = {
	history: createWebHistory(),
	routes: [

		...assessmentRoutes,
		...toolsRoutes,
		...loginRoutes,
		...workflowGlobalRoutes,
		...resourcesGlobalRoutes,
		...packageGlobalRoutes,

		{
			path: '/app/action',
			name: 'FirebaseActionPage',
			component: FirebaseActionPage,
			meta:{
				isWhitelisted: true
			}
		},

		{
			path: '/app/invoice/pay/:invoiceGroupId/:billToId',
			redirect: '/app/invoice/pay/path/:invoiceGroupId/:billToId',
		},

		{
			path: '/app/invoice/pay/:coachId/:invoiceGroupId/:billToId',
			name: 'InvoicePay',
			component: InvoicePay,
			props: true,
			meta:{
				isWhitelisted: true
			}
		},
		{
			path: '/app/accept-invite/:coachId',
			name: 'AcceptInviteRoute',
			component: AcceptInvitePage,
			props: route => ({
				coachId: route.params.coachId,
				inviteId: route.query.code,
				clientId: route.query.cid
			}),
			meta:{
				isWhitelisted: true
			}
		},

		{
			path:'/app/stripe/wizard',
			name:'StripeWizard',
			component: StripeSetupWizardMain,
			props: route => ({
				...route.params,
				...route.query,
				authUrl: route.query.authUrl || route.params.authUrl,
			})
		},
		{
			path:'/app/clover/wizard',
			name:'CloverWizard',
			component: CloverSetupWizardMain,
			props: route => ({
				...route.params,
				...route.query,
				authUrl: route.query.authUrl || route.params.authUrl,
			})
		},
		{
			path: '/app/lnk',
			component: LinkRedirectPage,
			props: route => {
				let params = JSON.parse(fromBase64(route.query.p))
				let extraQuery = {...route.query}
				delete extraQuery.p
				params.query = {...params.query, ...extraQuery}
				console.log("/app/lnk: ", route, ", params = ", params)
				return {
					routeName: params.name,
					routeParams: params.params,
					routeQuery: params.query,
				}
			},
			meta:{
				isWhitelisted: true
			}
		},

		{
			path:'/app/no-path',
			name:'QwoachMain',
			redirect: '/app'
		},

		{
			path: '/app',
			name: 'Coach',
			component: Layout,
			redirect: '/app/coaching/processes',
			meta:{
				coachAuth:true
			},
			children: [
				// ...resourcesRoutes,
				...peopleRoutes,
				...profileRoutes(),
				// ...billingRoutes,
				...schedulerRoutes(),
				...coachingProgramRoutes,

				{
					// path: '*',
					path: ':catchAll(.*)*',
					redirect: '/app'
				},

			]
		},

		{
			path: '/cl',
			name: 'ClientPortalOldRouteName',
			redirect: '/cl/coaching/processes',
			component: LayoutClient,
			meta:{
				clientAuth:true
			},
			children: [

				{
					path: 'coaching/processes',
					name: 'ProgramListClient',
					component: ProgramListClient,
				},
				{
					path: 'coaching/processes/:programId',
					name: 'ProgramDetailsClient',
					component: ProgramDetailsClient,
					props:true
				},

				{
					// path: '*',
					path: ':catchAll(.*)*',
					redirect: '/cl'
				},
			]
		},

		...recoveryCoaching,



		...publicSchedulerRoutes,

	],

	scrollBehavior: (to, from, savedPosition) => {
		// savedPosition = savedPosition || to.params.savedPosition
		console.log("scrollBehavior: savedPosition = ", savedPosition)

		if (savedPosition) {
			return savedPosition;
		} else if (to.hash) {
			return {
				selector: to.hash
			};
		}
		// else {
		// 	return { x: 0, y: 0 };
		// }
	}
}


let router = createRouter(routerOptions)
// import store from '@/store'

export {
	router
}

export default (authManager, store) => {
	let routerManager = new RouterManager(router, authManager, store)
	return routerManager
}
